var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-2 infoContainer shadow-none",attrs:{"id":"workOrderInfoContainer"}},[_c('b-button',{staticClass:"mr-2 infoContainer",staticStyle:{"border":"none","background":"white","display":"flex","width":"100%","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.toggleWorkOrderInfoContainer()}}},[_vm._v(_vm._s(_vm.$t("general.workOrderInfo"))+" "),_c('i',{class:{
          'fas fa-angle-down': !_vm.workOrderInfoContainerOpened,
          'fas fa-angle-up': _vm.workOrderInfoContainerOpened,
        }})]),_c('b-collapse',{model:{value:(_vm.workOrderInfoContainerOpened),callback:function ($$v) {_vm.workOrderInfoContainerOpened=$$v},expression:"workOrderInfoContainerOpened"}},[_c('b-card',{staticClass:"shadow-none",staticStyle:{"margin-top":"-6px","margin-bottom":"0"}},[_c('loading',{attrs:{"active":_vm.isLoadingWorkOrderInfo,"can-cancel":false,"color":"#f1b44c","width":70,"height":70,"loader":"dots","is-full-page":false},on:{"update:active":function($event){_vm.isLoadingWorkOrderInfo=$event}}}),_c('div',[_c('h5',{staticClass:"infoContainer"},[_vm._v(" "+_vm._s(_vm.selectedWorkOrder.clientName)+" ")]),_c('div',{},[_c('label',{staticClass:"text-muted mr-1 mb-1"},[_vm._v(" "+_vm._s(_vm.selectedWorkOrder.clientPhone)+" ")])]),_c('div',{},[_c('label',{staticClass:"text-muted mr-1 mb-1"},[_vm._v(" "+_vm._s(_vm.selectedWorkOrder.clientAddress)+" ")])]),_c('div',{staticClass:"mb-3"},[_c('div',{},[_c('label',{staticClass:"text-muted mr-1 mb-1"},[_vm._v(" "+_vm._s(_vm.selectedWorkOrder.name)+" ")]),(_vm.selectedWorkOrder.template)?_c('label',{staticClass:"text-muted mr-1 mb-1"},[_vm._v(" | "+_vm._s(_vm.selectedWorkOrder.template.name)+" ")]):_vm._e(),(_vm.selectedWorkOrder.jobType)?_c('label',{staticClass:"text-muted mr-1 mb-1"},[_vm._v(" | "+_vm._s(_vm.selectedWorkOrder.jobType.name)+" ")]):_vm._e(),(_vm.selectedWorkOrder.workOrderGroup)?_c('label',{staticClass:"text-muted mr-1 mb-1"},[_vm._v(" | "+_vm._s(_vm.selectedWorkOrder.workOrderGroup.name)+" ")]):_vm._e()]),(_vm.selectedWorkOrder.productionDate && _vm.userCanSeeProduction())?_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("general.productionDate"))+": "+_vm._s(_vm.$dayjs(_vm.selectedWorkOrder.productionDate).format("L"))),_c('br')]):_vm._e(),(_vm.selectedWorkOrder.deliveryDate && _vm.userCanSeeAnyShipper())?_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("general.deliveryDate"))+": "+_vm._s(_vm.$dayjs(_vm.selectedWorkOrder.deliveryDate).format("L"))),_c('br')]):_vm._e(),(
                _vm.selectedWorkOrder.installationDate && _vm.userCanSeeAnyInstaller()
              )?_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("general.installationDate"))+": "+_vm._s(_vm.$dayjs(_vm.selectedWorkOrder.installationDate).format("L"))+" ")]):_vm._e()]),(_vm.userCanCreateWorkOrder)?_c('div',{staticClass:"mb-4",staticStyle:{"display":"flex"}},[_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$root.$emit('edit-workorder', _vm.selectedWorkOrder.idPublic)}}},[_vm._v(_vm._s(_vm.$t("general.edit")))]),(_vm.userCanDeleteWorkOrder)?_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.$emit('btn-delete-clicked')}}},[_vm._v(_vm._s(_vm.$t("general.delete")))]):_vm._e()],1):_vm._e(),(_vm.selectedWorkOrder.progressAppointments != null)?_c('div',_vm._l((_vm.sortedSelectedWorkOrderProgressAppointments),function(progressAppointment,index){return _c('div',{key:index,staticClass:"mb-4"},[_c('div',{staticClass:"row no-gutters",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col-8",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"rounded",staticStyle:{"margin-right":"8px","width":"10px","height":"20px"},style:({
                        background: progressAppointment.progress.colorHexa,
                      })}),_c('label',{staticClass:"text-muted mb-0"},[_vm._v(" "+_vm._s(progressAppointment.progress.name)+" ")])]),_c('div',{staticStyle:{"display":"flex","flex":"1 1 0","align-items":"end"}},[(progressAppointment.status != 'Completed')?_c('b-button',{staticClass:"mr-2",attrs:{"disabled":progressAppointment.status == 'Started' ||
                          progressAppointment.status == 'Completed',"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.startProgressAppointment(progressAppointment)}}},[_vm._v(_vm._s(progressAppointment.status != "Started" && progressAppointment.status != "Completed" ? _vm.$t("general.start") : _vm.$dayjs(progressAppointment.dateStarted).format("L")))]):_vm._e(),(progressAppointment.status != 'Completed')?_c('b-button',{staticClass:"mr-2",attrs:{"disabled":progressAppointment.status == 'Completed',"size":"sm","variant":"outline-success"},on:{"click":function($event){return _vm.confirmBeforeComplete(progressAppointment)}}},[_vm._v(_vm._s(_vm.$t("general.complete")))]):_vm._e()],1)]),_c('div',{staticClass:"col-4"},[_c('div',{style:({
                      color:
                        _vm.getAppointmentLabelColor(progressAppointment) +
                        ' !important',
                    })},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.getAppointmentDateForJobInfo(progressAppointment))+" ")]),(progressAppointment.status != 'Completed')?_c('div',[_c('radial-progress-bar',{staticStyle:{"margin":"0 auto"},attrs:{"diameter":47,"strokeWidth":3,"startColor":"#f1b44c","stopColor":"#f1b44c","innerStrokeColor":"#cfcfcf","innerStrokeWidth":3,"strokeLinecap":"square","completed-steps":progressAppointment.workOrderStepPercentCompleted *
                            100,"total-steps":100}},[_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"0.8em"}},[_vm._v(_vm._s(Math.floor( progressAppointment.workOrderStepPercentCompleted * 100 ))+"%")])])],1):_vm._e()])])]),_c('hr')])}),0):_vm._e()])],1)],1),_c('CompleteStepsBeforeModal',{ref:"completeStepsBeforeModal"}),_c('ConfirmModal',{ref:"confirmModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }