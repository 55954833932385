<script lang="jsx">
import Calendar from "./components/calendar";
import { formatDate } from "@fullcalendar/core";
import Repositories from '@/repositories';

import appConfig from "@/app.config";
//import PageHeader from "@/components/page-header";
import CalendarToolBar from "./components/calendar-tool-bar";
import CalendarApiService from "@/services/calendarService";
const EventsRepository = Repositories.get('events');
import ConfirmMoveBrokenLinkModal from "./modals/confirmMoveBrokenLinkModal";
import MoveProgressEventsModal from "./modals/moveProgressEventsModal";
import UserEventModal from "./components/user-event-modal";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
import UserConfigMixin from "@/mixins/userConfigMixin";
import WorkOrderSearchTypeahead from "@/components/widgets/workOrderSearchTypeahead";
import EventService from "@/services/event-service";
import DatePicker from "vue2-datepicker";
import DeletionModal from "@/components/widgets/deletion-modal.vue"
import FieldFilters from "@/components/widgets/field-filters.vue";
import WorkOrderTypeMultiSelect from "@/components/workordertype-multiselect";
import UsersMultiSelect from "@/components/users-multi-select.vue";

export default {
  mixins: [UserConfigMixin],
  page: {
    title: "Planning",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    CalendarToolBar,
    Calendar,
    ConfirmMoveBrokenLinkModal,
    VueSimpleContextMenu,
    MoveProgressEventsModal,
    UserEventModal,
    WorkOrderSearchTypeahead,
    DatePicker,
    DeletionModal,
    FieldFilters,
    WorkOrderTypeMultiSelect,
    UsersMultiSelect
  },
  data() {
    return {
      title: "Calendar",
      eventContextMenuOpened: false,
      dayCellContextMenuOpened: false,
      toolBarOpened: true,
      calendarKey: 0,
      search: false,
      selectedDate: this.$dayjs().format("YYYY-MM-DD"),
      isLoading: false,
      calendarReady: false,
      clickCnt: 0,
      selectedEventsList: [],
      selectedEvent: null,
      oneClickTimer: null,
      calendarState: {
        view: this.$store.state.calendar.calendarView,
        workOrderSearchInput: null,
        updateDisplayMonth: 0,
        blockerSourceReady:false,
        setSrc: false,
        sources: {
          steps: [],
          installers: [],
          shippers: [],
          production: []
        },
      },
      calendarOptions: {
        iconFields: [
          {
            name: "address",
            checked: true,
            icon: "mdi mdi-map-marker"
          },
          {
            name: "installer",
            checked: true,
            icon: "bx bxs-user",
            authorisation: this.userCanSeeAnyInstaller()
          },
          {
            name: "linkToReferenceDate",
            checked: true,
            icon: "mdi mdi-link-variant",
            authorisation: this.userCanSeeAnyProgress()
          },
          {
            name: "comment",
            checked: false,
            icon: "bx bxs-comment-dots"
          },
          {
            name: "workOrderComment",
            checked: false,
            icon: "bx bxs-comment-dots"
          },
          // new icons to put
          {
            name: "projectManager",
            checked: false,
            icon: "bx bxs-user-pin"
          },
          {
            name: "contractor",
            checked: false,
            icon: "bx bxs-business"
          },
          {
            name: "clientName2",
            checked: false,
            icon: "bx bxs-user-detail"
          },
          {
            name: "group",
            checked: false,
            icon: "mdi mdi-select-group"
          },
          {
            name: "projectType",
            checked: false,
            icon: "bx bxs-briefcase"
          },
          {
          name: "user",
          checked: true,
          icon: "mdi mdi-account-circle"
          },
          {
            name: "showCadCAm",
            checked: true,
            icon: "mdi mdi-database-check"
          },
        ],
        mobileScreen: false,
        fullCalendarOptions: {
          eventResize: (eventResizeInfo) => {
            this.eventResize(eventResizeInfo);
          },
          eventAllow: (dropInfo, draggedEvent) => {
            //Event allow drop/move
            if (!this.userCanEditEvent(draggedEvent)) {
              return false;
            }

            // ne pas pouvoir bouger les events de production
            if (
              !draggedEvent.extendedProps.userCreated &&
              draggedEvent.extendedProps.eventType == "ProductionEvent" &&
              dropInfo.allDay == false
            ) {
              return false;
            } else {
              return true;
            }
          },
          eventDrop: async (info) => {
            await this.eventDrop(info);
          },
          eventDidMount: (args) => {
            //set right click on event pour editer
            let el = args.el;
            if (args.event.extendedProps.eventType == "BlockerEvent") {
              var overlapEvents = this.$refs.calendar.$refs.fullCalendar.getApi()
                .getEvents()
                .filter(
                  (x) =>
                    x.extendedProps.eventType != "BlockerEvent" &&
                    x.startDate >= args.event.startDate &&
                    x.endDate <= args.event.endDate
                );
              overlapEvents.forEach((element) =>
                element.setProp("overlap", true)
              );
            }

            el.oncontextmenu = (e) => {
              this.eventContextMenuOpened = true;

              if (args.event.extendedProps.eventType == "InstallationEvent") {
                if (args.event.extendedProps.isMainEvent == false) {
                  this.$refs.installationEventContextMenuDuplicate.showMenu(e, args);
                } else {
                  this.$refs.installationEventContextMenu.showMenu(e, args);
                }
              }
              if (args.event.extendedProps.eventType == "BlockerEvent") {
                this.$refs.blockerEventContextMenu.showMenu(e, args);
              } else if (args.event.extendedProps.userCreated == false) {
                this.$refs.eventContextMenu.showMenu(e, args);
              } else {
                this.$refs.userEventContextMenu.showMenu(e, args);
              }

              //prevent standard context menu to open
              return false;
            };
            if (args.event.extendedProps.mountCallBack) {
              args.event.extendedProps.mountCallBack(args);
            }
          },
          eventOverlap: (stillEvent, movingEvent) => {
            return EventService.getSingleEventDoesNotOverlap(stillEvent, movingEvent);
          },
          dayCellDidMount: (args) => {
            let el = args.el;
            el.oncontextmenu = (e) => {
              if (!this.eventContextMenuOpened) {
                this.$refs.dayCellContextMenu.showMenu(e, args);
              }

              this.eventContextMenuOpened = false;
              //prevent standard context menu to open
              return false;
            };
          },
          resourceLaneDidMount: (args) => {
            let el = args.el;
            el.oncontextmenu = (e) => {
              if (!this.eventContextMenuOpened) {
                this.$refs.dayCellContextMenu.showMenu(e, args);
              }

              this.eventContextMenuOpened = false;
              //prevent standard context menu to open
              return false;
            };
          },
          // eslint-disable-next-line
          eventClick: (eventInfo) => {
            if (eventInfo.jsEvent.shiftKey) {
              this.clickCnt = 0;
              this.eventShiftClick(eventInfo);
            } else {
              this.clickCnt++;
              if (this.clickCnt === 1) {
                this.setEventClicked(eventInfo);
              } else if (this.clickCnt === 2) {
                if (eventInfo.event.extendedProps.eventType != "BlockerEvent") {
                  clearTimeout(this.oneClickTimer);
                  if (
                    eventInfo.event._def.publicId ==
                    this.selectedEvent._def.publicId
                  ) {
                    this.clickCnt = 0;
                    this.resetSelectedEventList();
                    this.editEvent(eventInfo);
                  } else {
                    this.clickCnt = 1;
                    this.setEventClicked(eventInfo);
                  }
                }
              }
            }
          },
          select: (info) => {
            this.resetSelectedEventList();

            let daysArray = this.getDaysBetween(info.start, info.end);

            //find all events for date range
            let events = this.$refs.calendar.$refs.fullCalendar.getApi().getEvents().filter((ev) => {
              let selectedDateStart = this.$dayjs(info.start);
              let selectedDateEnd = this.$dayjs(info.end);
              let eventStart = this.$dayjs(ev.start);
              let eventEnd =
                ev.end != null
                  ? this.$dayjs(ev.end)
                  : this.$dayjs(ev.start).add(1, "day");

              //un event ne fait PAS partie de la selection:
              //Si event.end <= selection.start
              //Si event.start >= selection.end
              let isOutsideRange =
                eventEnd.isSameOrBefore(selectedDateStart) ||
                eventStart.isSameOrAfter(selectedDateEnd);
              return (
                !(ev.extendedProps.eventType == "BlockerEvent" && ev.display == "block") &&
                !isOutsideRange
              );
            });
            this.updateToolBar(
              events.filter((x) => x.extendedProps.userCreated != true),
              daysArray.length,
              info.start,
              false
            );
          },
          dayCellClassNames: () => {
            let classNames = [];

            return classNames;
          },
          initialView: this.$store.state.calendar.calendarView,
        }
      },
      userFilter: []
    };
  },
  beforeCreate() {
    //fetch data to store
    this.$store.dispatch("calendar/fetchProgress");
    this.$store.dispatch("calendar/fetchInstallers");
    this.$store.dispatch("calendar/fetchShippers");
  },
  created() {
    this.setListeners();
  },
  mounted() {
    this.calendarReady = true;
    this.search = true;
    if (window.innerWidth <= 550) {
      this.$refs.calendar.setCalendarView('listWeek');
      this.calendarOptions.mobileScreen = true;
      this.search = false;
      this.toolBarOpened = false;
    }
    this.$root.$on("save-workorder", this.refetchEvents);
    for (let field of this.calendarOptions.iconFields) {
      field.checked = !localStorage.getItem(field.name) 
      ? false 
      : localStorage.getItem(field.name) == "true" ?? true;
    }
  },
  beforeDestroy() {
    this.removeEventListeners();
    this.$root.$off("save-workorder", this.refetchEvents);
  },
  computed: {
    assignable_users() {
      return this.$store.state.calendar.progress.map(x => x.users.map(x => { return {userId: x.userId, displayName: x.displayName};}) ?? []).flat().filter((user, index, userList)=> {
        return userList.indexOf(userList.find(list_user => list_user.userId === user.userId)) === index
      });
    },
    viewOptions() {
      return [
        {
          value: "dayGridMonth",
          text: this.$t("general.month"),
        },
        {
          value: "timeGridWeek",
          text: this.$t("general.week"),
        },
        {
          value: "workWeek",
          text: this.$t("general.workWeek"),
        },
        {
          value: "timeGridDay",
          text: this.$t("general.day"),
        },
        {
          value: "listWeek",
          text: this.$t("general.list"),
        },
      ];
    },
    selectedView() {
      return this.$store.state.calendar.calendarView;
    },
    appointmentStatus() {
      return this.$store.state.calendar.appointmentStatus;
    },
    currentCalendarMonth() {
      this.calendarState.updateDisplayMonth;
      return this.$refs.calendar.currentCalendarMonth;
    },
  },
  methods: {
    refetchEvents() {
      this.$refs.calendar.refetchEvents();
    },
    resetUserFilter() {
      this.userFilter.splice(0, this.userFilter.length);
    },
    selectResourceWithUsers() {
      this.$refs.calendarToolBar.selectResourceWithUsers(this.userFilter);
    },
    toggleRigthBar() {
      window.document.body.classList.add("right-bar-enabled");
    },
    setEventClicked(eventInfo) {
      this.selectedEvent = eventInfo.event;
      this.eventClick(eventInfo);
      this.oneClickTimer = setTimeout(() => {
        this.clickCnt = 0;
      }, 500);
    },
    eventResize(eventResizeInfo) {
      let type = eventResizeInfo.event.extendedProps.eventType;
      let userCreated = eventResizeInfo.event.extendedProps.userCreated;
      if (type == "StepEvent" || userCreated == true) {
        let eventId = eventResizeInfo.event.id;
        let endDate = this.$dayjs(eventResizeInfo.event.end).format(
          "YYYY-MM-DDTHH:mm"
        );
        CalendarApiService.SetAppointmentEndDate(eventId, endDate).then(() => {
          if (eventResizeInfo.event.extendedProps.userCreated == true) {
        this.refetchEvents();
          }
        });
      } else if (type == "DeliveryEvent") {
        let eventId = eventResizeInfo.event.id;
        let endDate = this.$dayjs(eventResizeInfo.event.end).format(
          "YYYY-MM-DDTHH:mm"
        );
        this.isLoading = true;
        CalendarApiService.SetDeliveryAppointmentEndDate(eventId, endDate).then(
          () => {
            this.isLoading = false;
        this.refetchEvents();
          }
        );
      } else if (type == "InstallationEvent") {
        let eventId = eventResizeInfo.event.id;
        let endDate = this.$dayjs(eventResizeInfo.event.end).format(
          "YYYY-MM-DDTHH:mm"
        );
        this.isLoading = true;
        CalendarApiService.SetInstallationAppointmentEndDate(
          eventId,
          endDate
        ).then(() => {
          this.isLoading = false;
        this.refetchEvents();
        });
      } else if (type == "BlockerEvent") {
        let eventId = eventResizeInfo.event.id;
        let endDate = this.$dayjs(eventResizeInfo.event.end).format(
          "YYYY-MM-DDTHH:mm"
        );
        this.isLoading = true;
        CalendarApiService.setBlockerEventEndDate(eventId, endDate).then(() => {
          this.isLoading = false;
        this.refetchEvents();
        });
      }
    },
    getDaysBetween(startDate, endDate) {
      var now = this.$dayjs(startDate);
      var end = this.$dayjs(endDate);
      var dates = [];

      while (now.isBefore(end)) {
        dates.push(this.$dayjs(now));
        now = now.add(1, "day");
      }
      return dates;
    },
    updateToolBarWorkOrderInfo(event) {
      this.$bus.$emit("update-tool-bar-work-order-info", event);
    },
    updateToolBarProgressCapacities(
      events,
      numberOfDaysSelected = 1,
      startDate = null,
      blockerEvents
    ) {
      this.$bus.$emit("update-tool-bar-progress-capacities", {
        events,
        numberOfDaysSelected,
        startDate,
        blockerEvents,
      });
    },
    updateToolBarInstallersCapacities(
      events,
      numberOfDaysSelected = 1,
      startDate = null,
      blockerEvents
    ) {
      this.$bus.$emit("update-tool-bar-installers-capacities", {
        events: events,
        numberOfDaysSelected: numberOfDaysSelected,
        startDate: startDate,
        blockerEvents,
      });
    },
    resetSelectedEventList() {
      for (let e of this.selectedEventsList) {
        e.setExtendedProp("selected", false);
      }
      this.selectedEventsList = [];
    },
    // eslint-disable-next-line no-unused-vars
    updateToolBar(
      selectedEventsList,
      numberOfDaysSelected = 1,
      startDate = null,
      displayWorkOrderInfo = true
    ) {
      var progressBlockers = selectedEventsList.filter(
        (e) =>
          e.extendedProps.eventType == "BlockerEvent" &&
          e.extendedProps.selectedProgress.length > 0
      );
      this.updateToolBarProgressCapacities(
        selectedEventsList.filter(
          (e) =>
            e.extendedProps.eventType == "StepEvent" &&
            e.extendedProps.userCreated == false
        ),
        numberOfDaysSelected,
        startDate,
        progressBlockers
      );

      if (displayWorkOrderInfo && selectedEventsList.length == 1) {
        this.updateToolBarWorkOrderInfo(selectedEventsList[0]);
      } else {
        this.updateToolBarWorkOrderInfo(null);
      }

      //Update tool bar installers capacities
      var installerBlockers = selectedEventsList.filter(
        (e) =>
          e.extendedProps.eventType == "BlockerEvent" &&
          e.extendedProps.selectedInstallers.length > 0
      );
      this.updateToolBarInstallersCapacities(
        selectedEventsList.filter(
          (e) =>
            e.extendedProps.eventType == "InstallationEvent" &&
            e.extendedProps.userCreated == false
        ),
        numberOfDaysSelected,
        startDate,
        installerBlockers
      );
    },
    eventClick(eventInfo) {
      let isSelected = eventInfo.event.extendedProps.selected;

      this.resetSelectedEventList();

      eventInfo.event.setExtendedProp("selected", !isSelected);
      //Toggle event selection
      if (!isSelected) {
        //select
        this.selectedEventsList.push(eventInfo.event);
      }

      let nbOfDaysAllEventsSpan = this.getNbOfDaysAllEventsSpan(
        this.selectedEventsList
      );
      this.updateToolBar(
        this.selectedEventsList.filter((x) => {
          return x.extendedProps.userCreated == false;
        }),
        nbOfDaysAllEventsSpan
      );
    },
    eventShiftClick(eventInfo) {
      let isSelected = eventInfo.event.extendedProps.selected;
      eventInfo.event.setExtendedProp("selected", !isSelected);

      if (eventInfo.event.extendedProps.selected) {
        this.selectedEventsList.push(eventInfo.event);
      } else {
        var removeIndex = this.selectedEventsList
          .map(function (item) {
            return item._def.publicId;
          })
          .indexOf(eventInfo.event._def.publicId);
        this.selectedEventsList.splice(removeIndex, 1);
      }
      let nbOfDaysAllEventsSpan = this.getNbOfDaysAllEventsSpan(
        this.selectedEventsList.filter((x) => {
          return x.extendedProps.userCreated == false;
        })
      );
      this.updateToolBar(
        this.selectedEventsList.filter((x) => {
          return x.extendedProps.userCreated == false;
        }),
        nbOfDaysAllEventsSpan
      );
    },
    getNbOfDaysAllEventsSpan(events) {
      if (events == null || events.length == 0) return 1;

      let smallestStartDate = events
        .map((e) => {
          return e.start;
        })
        .sort((a, b) => (this.$dayjs(a).isAfter(this.$dayjs(b)) ? 1 : -1))[0];
      let greatestEndDate = events
        .map((e) => {
          return e.end == null ? this.$dayjs(e.start).add(1, "day") : e.end;
        })
        .sort((a, b) => (this.$dayjs(a).isAfter(this.$dayjs(b)) ? -1 : 1))[0];

      let diff = this.$dayjs(smallestStartDate).diff(greatestEndDate, "day");

      return Math.abs(diff);
    },
    async updateReferenceAppointmentDate(info) {

      let workOrderResponse =
        await CalendarApiService.getWorkOrderByIdPublicNoWorkOrderSteps(
          info.event.extendedProps.workOrderIdPublic
        );
      let workOrder = workOrderResponse.data.data;
      let type = info.event.extendedProps.eventType;

      CalendarApiService.checkIfBrokenLink(
        info.event.extendedProps.workOrderIdPublic,
        type
      ).then((response) => {
        let brokenLinkExist = response.data.data;
        if (brokenLinkExist) {
          //show pop up demandant si on deplace les event avec un broken link
          this.$bus.$emit("open-confirm-move-broken-link-dialog", {
            callBack: (userChoice) => {
              if (userChoice == null) {
                //user cancel
                info.revert();
              } else {
                this.isLoading = true;
                let model = EventService.updateEventFormat(info, userChoice, workOrder, this.$store.state.calendar.shippers, this.$store.state.calendar.installers);
                let promise = EventsRepository.customREST.updateEvent(model).then(() => {
                  this.refetchEvents();
                });
                Promise.all([promise]).then(() => {
                  this.isLoading = false;
                })
              }
            },
          });
        } else {
          //move reference appointment avec true ou false si on veut que les broken link suivent ou pas
          this.isLoading = true;
          let model = EventService.updateEventFormat(info, true, workOrder, this.$store.state.calendar.shippers, this.$store.state.calendar.installers);
          let promise = EventsRepository.customREST.updateEvent(model).then(() => {
          this.refetchEvents();
          });
          Promise.all([promise]).then(() => {
            this.isLoading = false;
          })
        }
      });
    },
    async updateProgressAppointmentDate(info, startDate, endDate) {
      let appointmentId = info.event.id;
      let userIds = null;
      if(info.view.type === 'quickView' && info.newResource !== null && ((info.newResource.id === info.oldResource.parentId && info.oldResource.getParent() !== null) 
      || (info.newResource.parentId === info.oldResource.parentId && info.oldResource.getParent() !== null)
      || (info.newResource.parentId === info.oldResource.id) && info.newResource.getParent() !== null)) {
        userIds = info.newResource.id.includes('00000000-0000-0000-0000-000000000000') ? [] : [info.newResource.id]
      }

      let isMoveLater = (
        await CalendarApiService.getIsMoveLaterProgress(
          info.event.extendedProps.stepIds[0]
        )
      ).data.data;
      let oldStartDay = this.$dayjs(info.event.start).format("YYYY-MM-DD");
      let newStartDay = this.$dayjs(info.oldEvent.start).format("YYYY-MM-DD");
      if (isMoveLater && oldStartDay !== newStartDay) {
        //get progress to move
        let workOrderIdPublic = info.event.extendedProps.workOrderIdPublic;
        this.isLoading = true;
        let response = await CalendarApiService.getLaterProgressAppointmentsV1_1(
          workOrderIdPublic,
          info.event.extendedProps.stepIds[0]
        );
        this.isLoading = false;
        //pop up pour demander quels events bouger
        let laterProgressEvents = response.data.data;
        if (laterProgressEvents.length == 0) {
          this.setProgressAppointmentDate(
            appointmentId,
            startDate,
            endDate,
            info.event.allDay,
            undefined,
            userIds
          );
        } else {
          this.$bus.$emit("open-move-progress-events-dialog", {
            events: laterProgressEvents,
            callBack: (selectedEvents) => {
              if (selectedEvents == null) {
                //user cancel
                info.revert();
              } else {
                this.setProgressAppointmentDate(
                  appointmentId,
                  startDate,
                  endDate,
                  info.event.allDay,
                  selectedEvents,
                  userIds
                );
              }
            },
          });
        }
        return;
      }
      this.setProgressAppointmentDate(
        appointmentId,
        startDate,
        endDate,
        info.event.allDay,
        undefined,
        userIds
      );
    },
    async eventDrop(info) {
      if(info.newResource !== null 
        && info.newResource.extendedProps.resourceType !== info.oldResource.extendedProps.resourceType 
        && (info.event.extendedProps.eventType !== "StepEvent")
      ) {
        info.revert();
        return;
      }

      //save new event start date
      let oldStartDay = this.$dayjs(info.event.start).format("YYYY-MM-DD");
      let newStartDay = this.$dayjs(info.oldEvent.start).format("YYYY-MM-DD");

      let startDate = this.$dayjs(info.event.start).format("YYYY-MM-DDTHH:mm");
      let endDate = EventService.getEndDate(info.event);

      if(info.event.extendedProps.userCreated == true) {
        if(oldStartDay === newStartDay) {
          info.event.setEnd(endDate);
        }
        this.setUserAppointmentDate(info.event);
        return;
      }
      if(info.event.extendedProps.eventType === 'StepEvent') {

        this.updateProgressAppointmentDate(info, startDate, endDate);
        return;
      }
      if(info.view.type == "quickView" 
        && info.oldEvent.extendedProps.eventType == "InstallationEvent" 
        && oldStartDay === newStartDay 
        && info.oldEvent.extendedProps.installers.length > 1) {
        info.revert();
        return;
      }
      
      if (
        info.event.extendedProps.eventType == "InstallationEvent" ||
        info.event.extendedProps.eventType == "DeliveryEvent" ||
        info.event.extendedProps.eventType == "ProductionEvent"
      ) {
        if(oldStartDay !== newStartDay) {
          this.updateReferenceAppointmentDate(info);
        } else {
          this.isLoading = true;
          
      let workOrderResponse =
          await CalendarApiService.getWorkOrderByIdPublicNoWorkOrderSteps(
            info.event.extendedProps.workOrderIdPublic
          );
          let workOrder = workOrderResponse.data.data;
          let model = EventService.updateEventFormat(info, true, workOrder, this.$store.state.calendar.shippers, this.$store.state.calendar.installers);
          let promise = EventsRepository.customREST.updateEvent(model).then(() => {
            this.refetchEvents();
          });
          Promise.all([promise]).then(() => {
            this.isLoading = false;
          })
        }
        return;
      }
      return;
    },
    setUpdatedEventStart(updatedEvents) {
      for (var ev of updatedEvents) {
        var event = this.$refs.calendar.$refs.fullCalendar.getApi().getEventById(ev.id); 

        if (event) {
          event.setStart(ev.startDate, { maintainDuration: true });
          let workOrder = event.extendedProps.workOrder;
          if (event.extendedProps.eventType == "InstallationEvent") {
            workOrder.installationDate = this.$dayjs(ev.startDate).format(
              "YYYY-MM-DD"
            );
          } else if (event.extendedProps.eventType == "DeliveryEvent") {
            workOrder.deliveryDate = this.$dayjs(ev.startDate).format(
              "YYYY-MM-DD"
            );
          }
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    setProgressAppointmentDate(
      appointmentId,
      startDate,
      endDate,
      allDay = false,
      eventsToMove = [],
      userIds = []
    ) {
      this.isLoading = true;
      CalendarApiService.setProgressAppointmentDate({
        appointmentId,
        startDate,
        endDate,
        allDay,
        appointmentsToMove: eventsToMove,
        userIds: userIds
      }).then((response) => {
        this.isLoading = false;
        if (response.data.success) {
          this.refetchEvents();
        }
      });
    },
    setReferenceAppointmentDate(
      type,
      appointmentId,
      startDate,
      endDate,
      allDay,
      moveBrokenLink,
      isMainEvent
    ) {
      this.isLoading = true;
      CalendarApiService.setReferenceAppointmentDate(
        type,
        appointmentId,
        startDate,
        endDate,
        allDay,
        moveBrokenLink,
        isMainEvent
      ).then(() => {
        this.isLoading = false;
        this.refetchEvents();
      });
    },
    setUserAppointmentDate(event) {
      let appointmentId = event.id;
      let allDay = event.allDay;
      let startDate = this.$dayjs(event.start).format("YYYY-MM-DDTHH:mm");
      let endDate = EventService.getEndDate(event);

      this.isLoading = true;
      CalendarApiService.setUserAppointmentDate(
        appointmentId,
        startDate,
        endDate,
        allDay
      ).then(() => {
        this.isLoading = false;
        this.refetchEvents();
      });
    },
    closeToolbar: function () {
      setTimeout(
        function () {
          this.$refs.calendar.$refs.fullCalendar.getApi().updateSize();
        }.bind(this),
        10
      );
    },
    toggleLeftMenu: function () {
      setTimeout(
        function () {
          this.$refs.calendar.$refs.fullCalendar.getApi().updateSize();
        }.bind(this),
        0
      );
    },
    changeToolBarDate: function (newDate) {
      this.$refs.calendar.goToDate(newDate);
    },
    saveColDisplay() {
      for (let field of this.calendarOptions.iconFields) {
        localStorage.setItem(
          field.name,
          field.checked
        );
      }
    },
    setListeners() {
      this.$root.$on("closeToolbar", this.closeToolbar);
      this.$root.$on("toggleLeftMenu", this.toggleLeftMenu);
      this.$root.$on("change-tool-bar-date", this.changeToolBarDate);
      this.$root.$on("completeProgressEvent", this.refetchEvents);
      this.$root.$on("startProgressEvent", this.refetchEvents);
    },
    removeEventListeners() {
      this.$root.$off("closeToolbar", this.closeToolbar);
      this.$root.$off("toggleLeftMenu", this.toggleLeftMenu);
      this.$root.$off("change-tool-bar-date", this.changeToolBarDate);
      this.$root.$off("completeProgressEvent", this.refetchEvents);
      this.$root.$off("startProgressEvent", this.refetchEvents);
    },
    getCurrentDisplayedMonth() {
      return formatDate(this.calendar.getDate(), {
        month: "long",
        year: "numeric",
        locale: this.$i18n.locale,
      });
    },
    closeToolBar() {
      this.toolBarOpened = false;
    },
    openToolbar() {
      this.toolBarOpened = true;

      setTimeout(
        function () {
          this.$refs.calendar.$refs.fullCalendar.getApi().updateSize();
        }.bind(this),
        1
      );
    },
    editEvent(eventInfo) {
      //close work order info
      this.updateToolBarWorkOrderInfo(null);

      if (this.userCanEditEvent(eventInfo.event)) {
        this.$parent.$refs.eventDetailsModal.openEditEvent(eventInfo.id, { eventInfo }).then(() => {
        this.refetchEvents();
        });
      }
    },
    searchAppointments() {
      if (this.calendarState.workOrderSearchInput != null) {
        this.calendarState.setSrc = true;
        this.refetchEvents();
      }
    },
    eventOptionClicked(args) {
      let event = args.item.event;

      let option = args.option.code;
      if (option == "edit") {
        let eventInfo = {
          event: event,
        };
        this.editEvent(eventInfo);
      }
    },
    installationEventOptionClicked(args) {
      let eventInfo = {
        event: args.item.event,
      };
      if (args.option.code == "edit") {
        this.updateToolBarWorkOrderInfo(null);
        if (this.userCanEditEvent(eventInfo.event)) {
          this.$parent.$refs.eventDetailsModal.openEditEvent(eventInfo.id, { eventInfo }).then(() => {
            this.refetchEvents();
          });
        }
      }
      if (args.option.code == "duplicate") {
        this.updateToolBarWorkOrderInfo(null);
        if (this.userCanEditEvent(eventInfo.event)) {
          this.$parent.$refs.eventDetailsModal.openEditEvent(eventInfo.id, { eventInfo }, args.option.code).then(() => {
            this.refetchEvents();
          });
        }
      }
      if (args.option.code == "delete") {
        this.$refs.installationDeletionModal.show().then(() => {
          CalendarApiService.deleteEvent(eventInfo.event.id).then(() => {
        this.refetchEvents();
            this.trackEvent("DeleteInstallationEvent");
          });
        });
      }
    },
    blockerEventOptionClicked(args) {
      let event = args.item.event;

      let option = args.option.code;
      if (option == "delete") {
        this.$refs.blockerDeletionModal.show().then(() => {
          CalendarApiService.deleteEvent(event.id).then(() => {
        this.refetchEvents();
            this.trackEvent("DeleteBlockerEvent");
          });
        });
      } else if (option == "edit") {
        this.$refs.userEventModal.openBlockerEventDialog(event).then(
          () => {
        this.refetchEvents();
          },
          () => { }
        );
      }
    },
    userEventOptionClicked(args) {
      let event = args.item.event;

      let option = args.option.code;
      if (option == "edit") {
        let eventInfo = {
          event: event,
        };
        this.editEvent(eventInfo);
      } else if (option == "delete") {
        this.$refs.userDeletionModal.show().then(() => {
          if (this.calendarState.blockerSourceReady === true) {
            this.$refs.calendar.refetchEventSource("BlockerEvent");
          }
          CalendarApiService.deleteUserEvent(event.id).then(() => {
            this.refetchEvents();
            this.trackEvent("DeleteUserEvent");
          });
        });
      }
    },
    dayCellOptionClicked(args) {
      let option = args.option.code;

      if (option == "createEvent") {
        this.createEvent(args);
      } else if (option == "createBlockerEvent") {
        this.createBlockerEvent(args);
      }
    },
    createBlockerEvent(args) {
      let startDate = this.$dayjs(args.item.date).format("YYYY-MM-DDTHH:mm");

      let selectedProgress = this.$refs.calendarToolBar.progress.filter(
        function (p) {
          return p.checked;
        }
      );
      let selectedInstallers = this.$refs.calendarToolBar.installers.filter(
        function (i) {
          return i.checked && i.id != 0;
        }
      );
      let selectedShippers = this.$refs.calendarToolBar.shippers.filter(
        function (s) {
          return s.checked && s.id != 0;
        }
      );
      this.$refs.userEventModal
        .openBlockerEventDialog({
          start: startDate,
          selectedProgress,
          selectedInstallers,
          selectedShippers,
        })
        .then(
          () => {
        this.refetchEvents();
          },
          () => { }
        );
    },
    createEvent(args) {
      let startDateTime = this.$dayjs(args.item.date).format(
        "YYYY-MM-DDTHH:mm"
      );

      let selectedProgress = this.$refs.calendarToolBar.progress.filter(
        function (p) {
          return p.checked;
        }
      );
      let selectedInstallers = this.$refs.calendarToolBar.installers.filter(
        function (i) {
          return i.checked && i.id != 0;
        }
      );
      let selectedShippers = this.$refs.calendarToolBar.shippers.filter(
        function (s) {
          return s.checked && s.id != 0;
        }
      );
      this.$refs.userEventModal
        .openCreateUserEventDialog({
          startDateTime,
          selectedProgress,
          selectedInstallers,
          selectedShippers,
        })
        .then(
          () => {
        this.refetchEvents();
          },
          () => { }
        );
    },
    quickView() { },
    // eslint-disable-next-line no-unused-vars
    async showOnCalendar(workOrder) {
      //amener le calendrier vers une page ou le work order se situe
      //depend de ce que le user à choisi comme calendrier à afficher
      //Si le user na aucun calendrier de choisi, ne rien faire
      var dateToGo = this.$dayjs().format("YYYY-MM-DD");
      var checkedProgress = this.calendarState.sources.steps;
      var checkedShippers = this.calendarState.sources.shippers;
      var checkedInstallers = this.calendarState.sources.installers;
      if (checkedProgress.length > 0) {
        //Si le user a un ou des progres choisis, en prendre un et demander une date pour le progres du work order au Back-end
        var progress = checkedProgress[0];
        dateToGo = (
          await CalendarApiService.getProgressAppointmentDateV1_1(
            progress.id,
            workOrder.idPublic
          )
        ).data.data;
      } else if (checkedShippers.length > 0) {
        //Si le user a un ou plusieur livreur choisi, demander la date de livraison du work order au back-end
        dateToGo = (
          await CalendarApiService.getDeliveryDate(workOrder.idPublic)
        ).data.data;
      } else if (checkedInstallers.length > 0) {
        //Si le user a un oun plusieurs installateurs choisis, demander la date
        dateToGo = (
          await CalendarApiService.getInstallationDate(workOrder.idPublic)
        ).data.data;
      }

      if (dateToGo != null) {
        if (
          this.$dayjs(dateToGo).format("YYYY-MM-DD") !=
          this.$dayjs().format("YYYY-MM-DD")
        ) {
          this.$refs.calendar.goToDate(dateToGo);
        } else {
          this.refetchEvents();
        }
      }
    },
    onConfirmDeleteBlockerEvent(data) {
      if (data.userResponse == true) {
        CalendarApiService.deleteEvent(data.eventId).then(() => {
          this.refetchEvents();
          this.trackEvent("DeleteBlockerEvent");
        });
      }
    },
    onConfirmDeleteInstallationEvent(data) {
      if (data.userResponse == true) {
        CalendarApiService.deleteEvent(data.eventId).then(() => {
          this.refetchEvents();
          this.trackEvent("DeleteInstallationEvent");
        });
      }
    },
    onConfirmDeleteUserEvent(data) {
      if (this.blockerSourceReady === true) {
        this.$refs.calendar.refetchEventSource("BlockerEvent");
        this.$refs.calendar.refetchEventSource("UserEvent");
      }
      if (data.userResponse == true) {
        CalendarApiService.deleteUserEvent(data.eventId).then(() => {
          this.refetchEvents();
          this.trackEvent("DeleteUserEvent");
        });
      }
    },
    toolBarAllSet() {
      this.calendarState.blockerSourceReady = true;
      this.$refs.calendar.refetchEventSource("BlockerEvent");
      this.$refs.calendar.refetchEventSource("UserEvent");
    },
  },
};
</script>

<template>
  <Layout :contentPadding="false">
    <template v-slot:page_configuration_menu>
      <div class="rightbar-title px-3 pt-4 pb-2">
        <h5 class="m-0">{{ $t("general.calendarpreferences") }}</h5>
      </div>
      <hr class="mt-0" />
      <div class="p-3">
        <h6 class="mb-2">{{ $t("general.columns") }}</h6>
        <template v-for="field in calendarOptions.iconFields">
          <b-form-checkbox
            @change="saveColDisplay"
            class="mr-3"
            size="md"
            switch
            v-model="field.checked"
            :key="field.name"
          >
            <span style="white-space: nowrap">
              <i
                v-if="field.icon"
                style="font-size: 1.2em"
                :class="field.icon + ' mr-1'"
              />
              {{ $t(`general.${field.name}`) }}
            </span>
          </b-form-checkbox>
        </template>
        <hr class="mt-1" />
      </div>
      <div class="rightbar-title px-3 pt-4 pb-2">
        <h5 class="m-0">{{ $t("general.filters") }}</h5>
      </div>
      <hr class="mt-0" />
      <div class="p-3">
        <h6 class="mb-2">{{ $t("general.workOrdersType") }}</h6>
        <WorkOrderTypeMultiSelect @reloadCalendarEvents="refetchEvents" />
        <hr class="mt-1" />
        <h6 class="mb-0">{{ $t("general.assignedUsers") }}</h6>
        <UsersMultiSelect
          trackBy="userId"
          :users="assignable_users"
          v-model="userFilter"
          @change="selectResourceWithUsers"
        />
        <hr class="mt-1" />
      </div>
    </template>
    <vue-simple-context-menu
      :elementId="'1'"
      :options="[{ code: 'edit', name: $t('general.edit') }]"
      :ref="'eventContextMenu'"
      @option-clicked="eventOptionClicked"
    />
    <vue-simple-context-menu
      :elementId="'2'"
      :options="[
        { code: 'createEvent', name: $t('general.createEvent') },
        { code: 'createBlockerEvent', name: $t('general.createBlockerEvent') },
      ]"
      :ref="'dayCellContextMenu'"
      @option-clicked="dayCellOptionClicked"
    />
    <vue-simple-context-menu
      :elementId="'3'"
      :options="[
        { code: 'edit', name: $t('general.edit') },
        { code: 'delete', name: $t('general.delete') },
      ]"
      :ref="'userEventContextMenu'"
      @option-clicked="userEventOptionClicked"
    />
    <vue-simple-context-menu
      :elementId="'4'"
      :options="[
        { code: 'edit', name: $t('general.edit') },
        { code: 'delete', name: $t('general.delete') },
      ]"
      :ref="'blockerEventContextMenu'"
      @option-clicked="blockerEventOptionClicked"
    />
    <vue-simple-context-menu
      :elementId="'5'"
      :options="[
        { code: 'edit', name: $t('general.edit') },
        { code: 'duplicate', name: $t('general.duplicate') },
        { code: 'delete', name: $t('general.delete') },
      ]"
      :ref="'installationEventContextMenuDuplicate'"
      @option-clicked="installationEventOptionClicked"
    />
    <vue-simple-context-menu
      :elementId="'6'"
      :options="[
        { code: 'edit', name: $t('general.edit') },
        { code: 'duplicate', name: $t('general.duplicate') },
      ]"
      :ref="'installationEventContextMenu'"
      @option-clicked="installationEventOptionClicked"
    />
    <div
      style="width: 100%; display: flex; flex-direction: column; flex: 1 1 0"
      class="calendarPageContainer"
      v-bind:class="{ openCalendarToolBar: toolBarOpened }"
    >
      <CalendarToolBar
        ref="calendarToolBar"
        :onCloseToolBar="closeToolBar"
        :updating="false"
        @all-set="toolBarAllSet"
        @reloadCalendarEvents="refetchEvents"
        v-model="calendarState"
        @sourceChange="resetUserFilter"
      />
      <div
        class="mainCalendarContent"
        style="
          display: flex;
          flex: 1 1 0;
          flex-direction: column;
          z-index: 0;
          margin-left: 270px;
          position: relative;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        "
      >
        <b-container class="mt-3 px-4" fluid>
          <b-row>
            <b-col order="1" cols="1" md="5" lg="5" xl="7">
              <div id="toolBarButton">
                <i
                  class="mr-3 mdi mdi-cog-outline"
                  v-show="!toolBarOpened"
                  @click="openToolbar()"
                  style="
                    color: #6d6e71;
                    font-size: 2em;
                    cursor: pointer;
                    vertical-align: middle;
                  "
                ></i>
                <b-button
                  :class="`item-wrapper__item mr-2 ${
                    selectedView == 'quickView'
                      ? 'calendarViewBtn'
                      : 'activeCalendarViewBtn'
                  } calendar-main-view-btn`"
                  @click="
                    () => this.$refs.calendar.setCalendarView('dayGridMonth')
                  "
                >
                  {{ $t("general.calendar") }}
                </b-button>
                <b-button
                  :class="`item-wrapper__item mr-2 ${
                    selectedView != 'quickView'
                      ? 'calendarViewBtn'
                      : 'activeCalendarViewBtn'
                  } calendar-main-view-btn`"
                  @click="
                    () => this.$refs.calendar.setCalendarView('quickView')
                  "
                >
                  {{ $t("general.quickView") }}
                </b-button>
              </div>
            </b-col>

            <b-col
              class="d-none d-md-block"
              order="3"
              cols="3"
              md="3"
              lg="2"
              order-md="2"
            >
              <div
                v-if="selectedView != 'quickView'"
                class="text-right"
                id="calendarView"
              >
                <b-form-select
                  @change="
                    (value) => this.$refs.calendar.setCalendarView(value)
                  "
                  :value="selectedView"
                  :options="viewOptions"
                  style="max-width: 180px"
                >
                </b-form-select>
              </div>
            </b-col>

            <b-col class="d-sm-none pt-1" order="3">
              <date-picker
                class=""
                @change="changeToolBarDate"
                :clearable="false"
                :editable="false"
                :format="this.$dayjs.localeData().longDateFormat('L')"
                value-type="YYYY-MM-DD"
                v-model="selectedDate"
                :first-day-of-week="1"
                lang="fr"
              >
              </date-picker>
            </b-col>

            <b-col order="2" cols="11" md="4" lg="5" xl="3" order-md="3">
              <div class="text-right" id="search">
                <Transition name="fade">
                  <WorkOrderSearchTypeahead
                    v-if="search"
                    v-model="calendarState.workOrderSearchInput"
                    @search="searchAppointments"
                    @clear="this.refetchEvents"
                    @showOnCalendar="showOnCalendar"
                  />
                </Transition>
                <b-button
                  @click="search = !search"
                  class="ml-2 d-sm-none"
                  variant="warning"
                >
                  <i v-if="search" class="bx bx-x" />
                  <i v-else class="bx bx-search" />
                </b-button>
              </div>
            </b-col>

            <b-col class="pt-2" order="5" cols="12" md="6">
              <div id="calendarDate">
                <span
                  v-if="calendarReady"
                  style="font-size: 1.5em"
                  class="calendar-periode-display"
                >
                  {{ currentCalendarMonth }}
                </span>
              </div>
            </b-col>

            <b-col class="pt-1" order="4" order-md="5">
              <div class="text-right" id="calendarNavigation">
                <b-button-group>
                  <b-button
                    v-if="selectedView == 'quickView'"
                    @click="() => this.$refs.calendar.previousWeek()"
                    class="btn btn-primary calendarViewBtn"
                    style="
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    "
                  >
                    <i class="fa fa-chevron-left" />
                    <i class="fa fa-chevron-left" />
                  </b-button>
                  <b-button
                    v-if="selectedView == 'quickView'"
                    @click="() => this.$refs.calendar.previousDay()"
                    class="btn btn-primary calendarViewBtn"
                    style="
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    "
                  >
                    <i class="fa fa-chevron-left" />
                  </b-button>
                  <b-button
                    v-else
                    @click="() => this.$refs.calendar.previous()"
                    class="btn btn-primary calendarViewBtn"
                    style="
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    "
                  >
                    <i class="fa fa-chevron-left" />
                  </b-button>
                  <b-button
                    @click="() => this.$refs.calendar.next()"
                    class="btn btn-primary calendarViewBtn"
                    style="
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                    "
                  >
                    <i class="fa fa-chevron-right" />
                  </b-button>
                  <b-button
                    v-if="selectedView == 'quickView'"
                    @click="() => this.$refs.calendar.nextWeek()"
                    class="btn btn-primary mr-2 calendarViewBtn"
                    style="
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                    "
                  >
                    <i class="fa fa-chevron-right" />
                    <i class="fa fa-chevron-right" />
                  </b-button>
                </b-button-group>
                <b-button
                  @click="() => this.$refs.calendar.today()"
                  class="activeCalendarViewBtn ml-2"
                  >{{ $t("general.today") }}</b-button
                >
                <FieldFilters
                  v-if="
                    !calendarOptions.mobileScreen &&
                    !isTaskManagementOrganisationUser
                  "
                  class="ml-2 mt-2 mt-sm-0"
                  :fields="calendarOptions.iconFields"
                  @change="saveColDisplay"
                />
                <b-button
                  v-else-if="isTaskManagementOrganisationUser"
                  variant="primary"
                  class="toggle-right pb-1 px-2 ml-2 mt-2 mt-sm-0"
                  @click="toggleRigthBar"
                  ><i
                    style="font-size: 1.6em"
                    class="toggle-right bx bx-cog"
                  ></i
                ></b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <div
          class="pr-3 pl-3 pb-2 pt-2"
          style="
            flex: 1 1 0;
            position: relative;
            background-color: #f9fafc;
            display: flex;
            flex-direction: column;
          "
        >
          <Calendar
            ref="calendar"
            :options="calendarOptions"
            v-model="calendarState"
          />
        </div>
      </div>
    </div>

    <!-- event Modal -->
    <ConfirmMoveBrokenLinkModal />
    <MoveProgressEventsModal />
    <UserEventModal ref="userEventModal" />
    <DeletionModal
      ref="installationDeletionModal"
      :content="this.$t('general.deleteInstallationEventContent')"
      :title="this.$t('general.deleteInstallationEventTitle')"
    />
    <DeletionModal
      ref="blockerDeletionModal"
      :content="this.$t('general.deleteBlockerEventContent')"
      :title="this.$t('general.deleteBlockerEventTitle')"
    />
    <DeletionModal
      ref="userDeletionModal"
      :content="this.$t('general.deleteUserEventContent')"
      :title="this.$t('general.deleteUserEventTitle')"
    />
  </Layout>
</template>
<style scoped>
.calendarPageContainer >>> .fc-view {
  margin-top: 0;
}

.fc-datagrid-cell {
  /* height: 100; */
  background-color: #0000;
}
</style>