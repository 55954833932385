<script>
import RadialProgressBar from "vue-radial-progress";
import UserConfigMixin from "@/mixins/userConfigMixin";
import CalendarApiService from "@/services/calendarService";
import CompleteStepsBeforeModal from "@/router/views/modals/completeStepsBeforeModal";
import ConfirmModal from "@/components/confirm-modal.vue";
export default {
  mixins: [UserConfigMixin],
  components: {
    RadialProgressBar,
    CompleteStepsBeforeModal,
    ConfirmModal
  },
  model: {
    prop: "selectedWorkOrder",
  },
  props: ["selectedWorkOrder"],
  data() {
    return {
      workOrderInfoContainerOpened: true,
      isLoadingWorkOrderInfo: false,
    };
  },
  computed: {
    sortedSelectedWorkOrderProgressAppointments() {
      if (this.selectedWorkOrder == null) {
        return [];
      } else {
        var sorted = this.selectedWorkOrder.progressAppointments
          .slice()
          .sort((a, b) =>
            a.progress.order > b.progress.order
              ? 1
              : b.progress.order > a.progress.order
              ? -1
              : 0
          );
        var sortedVisible = sorted.filter((pa) => {
          return this.userCanSeeProgressInWorkOrderInfoSection(pa.progress);
        });
        return sortedVisible;
      }
    },
    appointmentStatus() {
      return this.$store.state.calendar.appointmentStatus;
    },
  },
  watch: {},
  methods: {
    toggleWorkOrderInfoContainer() {
      this.workOrderInfoContainerOpened = !this.workOrderInfoContainerOpened;
    },
    getAppointmentLabelColor(appointment) {
      let color = "";

      switch (appointment.status) {
        case "Pending":
          color = new Date(appointment.startDate).getTime() < new Date().getTime() ?  "#f46a6a" : 'inherit';
          break;
        case "Started":
          color = "#556ee6";
          break;
        case "Completed":
          color = "#34c38f";
          break;
      }

      return color;
    },
    getAppointmentDateForJobInfo(appointment) {
      let date = "";

      switch (appointment.status) {
        case "Pending":
          date = this.$dayjs(appointment.startDate).format("L");
          break;
        case "Started":
          date = this.$dayjs(appointment.startDate).format("L");
          break;
        case "Completed":
          date = this.$dayjs(appointment.dateCompleted).format("L");
          break;
      }

      return date;
    },
    startProgressAppointment(progressAppointment) {
      this.isLoadingWorkOrderInfo = true;
      CalendarApiService.startProgressAppointment(
        progressAppointment.workOrderStepIdPublic
      ).then(() => {
        this.isLoadingWorkOrderInfo = false;
        //Update side bar view
        let startedStatus = this.appointmentStatus.filter((s) => {
          return s == "Started";
        })[0];

        progressAppointment.dateStarted = this.$dayjs();
        progressAppointment.status = startedStatus;

        //update calendar view
        this.$root.$emit("startProgressEvent", progressAppointment.id);
      });
    },
    confirmBeforeComplete(progressAppointment) {
      this.$refs.confirmModal.show().then((response) => {
        if (response == true) {
          this.openCompleteStepsBeforeModal(progressAppointment);
        }
      })
    },
    openCompleteStepsBeforeModal(progressAppointment) {
      //get progress config
      this.isLoading = true;
      let progApp = progressAppointment;
      CalendarApiService.getProgressConfig(
        progressAppointment.progress.id
      ).then((response) => {
        this.isLoading = false;
        let config = response.data.data;
        if (
          config.askToCompletePreviousProgress == true &&
          progApp.progress.id != progApp.workOrder.nextProgressToComplete.id
        ) {
          this.$refs.completeStepsBeforeModal.show().then(
            (userResponse) => {
              this.completeProgressAppointment(progApp, userResponse);
            },
            () => {}
          );
        } else {
          this.completeProgressAppointment(progApp, true);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    completeProgressAppointment(
      progressAppointment,
      completeProgressBefore = false
    ) {
      this.isLoadingWorkOrderInfo = true;
      CalendarApiService.completeProgressAppointment(
        progressAppointment.workOrderStepIdPublic,
        completeProgressBefore
      ).then((response) => {
        let completedWorkOrderStepsIdPublic =
          response.data.data.completedWorkOrderStepsIdPublic;

        //Update view seulement pour le side bar
        //Set status to update side bar
        let completedStatus = this.appointmentStatus.filter((s) => {
          return s == "Completed";
        })[0];
        progressAppointment.status = completedStatus;
        progressAppointment.dateCompleted = this.$dayjs().format("YYYY-MM-DD");

        //completer appointments before dans le side bar
        for (var workOrderStepIdPublic of completedWorkOrderStepsIdPublic) {
          var matchingProgressAppointment = this.selectedWorkOrder.progressAppointments.filter(
            (a) => {
              return a.workOrderStepIdPublic == workOrderStepIdPublic;
            }
          );

          if (matchingProgressAppointment.length > 0) {
            matchingProgressAppointment = matchingProgressAppointment[0];
            matchingProgressAppointment.status = completedStatus;
            matchingProgressAppointment.dateCompleted = this.$dayjs().format(
              "YYYY-MM-DD"
            );
          }
        }

        this.$root.$emit("completeProgressEvent");

        this.isLoadingWorkOrderInfo = false;
      });
    },
  },
};
</script>
<template>
  <div id="workOrderInfoContainer" class="card mb-2 infoContainer shadow-none">
    <b-button
      class="mr-2 infoContainer"
      @click="toggleWorkOrderInfoContainer()"
      style="
          border: none;
          background: white;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        "
      >{{ $t("general.workOrderInfo") }}
      <i
        v-bind:class="{
          'fas fa-angle-down': !workOrderInfoContainerOpened,
          'fas fa-angle-up': workOrderInfoContainerOpened,
        }"
      ></i
    ></b-button>
    <b-collapse v-model="workOrderInfoContainerOpened" class="">
      <b-card style="margin-top: -6px; margin-bottom: 0" class="shadow-none">
        <loading
          :active.sync="isLoadingWorkOrderInfo"
          :can-cancel="false"
          color="#f1b44c"
          :width="70"
          :height="70"
          loader="dots"
          :is-full-page="false"
        ></loading>
        <div>
          <h5 class="infoContainer">
            {{ selectedWorkOrder.clientName }}
          </h5>
					<div class="">
						<label class="text-muted mr-1 mb-1">
							{{ selectedWorkOrder.clientPhone }}
						</label>
					</div>
					<div class="">
						<label class="text-muted mr-1 mb-1">
							{{ selectedWorkOrder.clientAddress }}
						</label>
					</div>
          <div class="mb-3">
            <div class="">
              <label class="text-muted mr-1 mb-1">
                {{ selectedWorkOrder.name }}
              </label>
              <label
                v-if="selectedWorkOrder.template"
                class="text-muted mr-1 mb-1"
              >
                | {{ selectedWorkOrder.template.name }}
              </label>
              <label
                v-if="selectedWorkOrder.jobType"
                class="text-muted mr-1 mb-1"
              >
                | {{ selectedWorkOrder.jobType.name }}
              </label>
              <label
                v-if="selectedWorkOrder.workOrderGroup"
                class="text-muted mr-1 mb-1"
              >
                | {{ selectedWorkOrder.workOrderGroup.name }}
              </label>
            </div>

            <small
              v-if="selectedWorkOrder.productionDate && userCanSeeProduction()"
              class="text-muted"
            >
              {{ $t("general.productionDate") }}:
              {{ $dayjs(selectedWorkOrder.productionDate).format("L") }}<br />
            </small>

            <small
              v-if="selectedWorkOrder.deliveryDate && userCanSeeAnyShipper()"
              class="text-muted"
            >
              {{ $t("general.deliveryDate") }}:
              {{ $dayjs(selectedWorkOrder.deliveryDate).format("L") }}<br />
            </small>

            <small
              v-if="
                selectedWorkOrder.installationDate && userCanSeeAnyInstaller()
              "
              class="text-muted"
            >
              {{ $t("general.installationDate") }}:
              {{ $dayjs(selectedWorkOrder.installationDate).format("L") }}
            </small>
          </div>

          <div style="display: flex" v-if="userCanCreateWorkOrder" class="mb-4">
            <b-button
              @click="$root.$emit('edit-workorder', selectedWorkOrder.idPublic)"
              class="mr-2"
              size="sm"
              variant="primary"
              >{{ $t("general.edit") }}</b-button
            >
            <b-button
              v-if="userCanDeleteWorkOrder"
              @click="$emit('btn-delete-clicked')"
              class="mr-2"
              size="sm"
              variant="danger"
              >{{ $t("general.delete") }}</b-button
            >
          </div>
          <div v-if="selectedWorkOrder.progressAppointments != null">
            <div
              class="mb-4"
              v-for="(progressAppointment,
              index) in sortedSelectedWorkOrderProgressAppointments"
              :key="index"
            >
              <div class="row no-gutters" style="display: flex">
                <div
                  class="col-8"
                  style="display: flex; flex-direction: column"
                >
                  <div style="display: flex; align-items: center">
                    <div
                      :style="{
                        background: progressAppointment.progress.colorHexa,
                      }"
                      class="rounded"
                      style="margin-right: 8px; width: 10px; height: 20px"
                    ></div>
                    <label class="text-muted mb-0">
                      {{ progressAppointment.progress.name }}
                    </label>
                  </div>
                  <div style="display: flex; flex: 1 1 0; align-items: end">
                    <b-button
                      @click="startProgressAppointment(progressAppointment)"
                      :disabled="
                        progressAppointment.status == 'Started' ||
                          progressAppointment.status == 'Completed'
                      "
                      v-if="progressAppointment.status != 'Completed'"
                      class="mr-2"
                      size="sm"
                      variant="outline-primary"
                      >{{
                        progressAppointment.status != "Started" &&
                        progressAppointment.status != "Completed"
                          ? $t("general.start")
                          : $dayjs(progressAppointment.dateStarted).format("L")
                      }}</b-button
                    >
                    <b-button
                      @click="confirmBeforeComplete(progressAppointment)"
                      :disabled="progressAppointment.status == 'Completed'"
                      v-if="progressAppointment.status != 'Completed'"
                      class="mr-2"
                      size="sm"
                      variant="outline-success"
                      >{{ $t("general.complete") }}</b-button
                    >
                  </div>
                </div>
                <div class="col-4">
                  <div
                    :style="{
                      color:
                        getAppointmentLabelColor(progressAppointment) +
                        ' !important',
                    }"
                  >
                    <div style="text-align: center">
                      {{ getAppointmentDateForJobInfo(progressAppointment) }}
                    </div>
                    <div v-if="progressAppointment.status != 'Completed'">
                      <radial-progress-bar
                        style="margin: 0 auto"
                        :diameter="47"
                        :strokeWidth="3"
                        startColor="#f1b44c"
                        stopColor="#f1b44c"
                        innerStrokeColor="#cfcfcf"
                        :innerStrokeWidth="3"
                        strokeLinecap="square"
                        :completed-steps="
                          progressAppointment.workOrderStepPercentCompleted *
                            100
                        "
                        :total-steps="100"
                      >
                        <span class="text-muted" style="font-size: 0.8em"
                          >{{
                            Math.floor(
                              progressAppointment.workOrderStepPercentCompleted *
                                100
                            )
                          }}%</span
                        >
                      </radial-progress-bar>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </b-card>
    </b-collapse>
    <CompleteStepsBeforeModal ref="completeStepsBeforeModal" />
    <ConfirmModal ref="confirmModal"/>
  </div>
</template>
